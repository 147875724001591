
















































































































































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import {
  Component, Prop, Provide, Vue, Watch,
} from 'vue-property-decorator';
import FeedItemCreator from '@/components/feed/creator/FeedItemCreator.vue';
import FeedItemWrapperComponent from '@/components/feed/FeedItemWrapperComponent.vue';
import { Getter, namespace } from 'vuex-class';
import FeedItemWrapper from '@/models/graphql/FeedItemWrapper';
import FeedType from '@/utils/enums/feed/FeedType';
import IllustrationType from '@/utils/enums/IllustrationType';
import CommunityUser from '@/models/graphql/CommunityUser';
import Exhibitor from '@/models/graphql/Exhibitor';
import SubEdition from '@/models/graphql/SubEdition';
import Channel from '@/models/graphql/Channel';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { StateChanger } from 'vue-infinite-loading';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { LATEST_FEED_POST_DELAY } from '@/utils/constants/Timer';
import { EventCallback } from '@/utils/event-bus/EventsBus';

const feedItemWrapperStore = namespace('FeedItemWrapperStore');
const communityUserStore = namespace('CommunityUserStore');
const exhibitorStore = namespace('ExhibitorStore');
const subEditionStore = namespace('SubEditionStore');
const feedStateStore = namespace('FeedStateStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    FontAwesomeComponent,
    LoadingSpinnerComponent,
    FeedItemCreator,
    FeedItemWrapperComponent,
    ButtonComponent,
  },
})
export default class FeedWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({
    required: false,
    default: null,
  })
  private readonly type!: string;

  @Provide('feedType')
  private feedType = Vue.observable({ value: '' });

  @Provide('companyId')
  private companyId = Vue.observable({ value: '' });

  @Provide('context')
  private context = 'Feed';

  @Prop({
    required: false,
    default: null,
  })
  private readonly entityCode!: string;

  private isLoading = true;

  @feedItemWrapperStore.Mutation
  private initialize!: () => void;

  @feedItemWrapperStore.Mutation
  private removePost!: (uid: string) => void;

  @feedItemWrapperStore.Mutation
  private setFeedInitiator!: (initiator: CommunityUser | Exhibitor | SubEdition | Channel) => Promise<void>;

  @feedItemWrapperStore.Action
  private loadFeeds!: (payload: {
    uid: string;
    type: string;
    authUserId: string;
    bookmarks: boolean;
  }) => Promise<number | void>;

  @feedItemWrapperStore.Action
  private loadLatestFeed!: (payload: {
    uid: string;
    type: string;
    authUserId: string;
    bookmarks: boolean;
  }) => Promise<FeedItemWrapper | undefined>;

  @feedItemWrapperStore.Getter
  private fetchCategories!: {
    name: string;
    condition: (item: FeedItemWrapper) => boolean;
    items: FeedItemWrapper[];
  }[];

  @feedItemWrapperStore.Action
  private getTopicsList!: (payload: {
    userUid: string;
    entityUid: string;
    isOrganiser: boolean;
  }) => void;

  @permissionManagerStore.Getter
  private canPostInCompany!: (companyUid: string) => boolean;

  @permissionManagerStore.Getter
  private canPostInSubEdition!: (subEditionId: string) => boolean;

  @feedItemWrapperStore.Getter
  private pinnedPosts!: {
    name: string;
    condition: (item: FeedItemWrapper) => boolean;
    items: FeedItemWrapper[];
  }[];

  @feedItemWrapperStore.Getter
  private pageNumber!: number;

  @feedItemWrapperStore.Getter
  private isTopicPublicPosting!: boolean;

  @feedItemWrapperStore.Getter
  private fetchLastFeedType!: string;

  @feedItemWrapperStore.Getter
  private entityUidFeed!: string;

  @exhibitorStore.Action
  private loadFeedExhibitor!: (payload: {
    uid: string;
    authUser: string;
  }) => Promise<void>;

  @subEditionStore.Action
  private getFeedSubEdition!: (code: string) => Promise<void>;

  @communityUserStore.Action
  private getFeedStateForAuthUser!: () => Promise<void>;

  @communityUserStore.Action
  private updateLastViewedFeedsGlobal!: (lastViewed: number) => void;

  @communityUserStore.Action
  private updateLastViewedFeeds!: (payload: { lastViewed: number; feedUid: string }) => void;

  @feedStateStore.Action('create')
  private createFeedState!: (payload: { userUid: string; lastView: number; feedUid: string }) => void;

  @feedStateStore.Action('update')
  private updateFeedState!: (payload: { lastView: number; feedStateUid: string }) => void;

  private showLastViewed = false;

  private isEmpty = false;

  private IllustrationType = IllustrationType;

  private FeatureKeys = FeatureKeys;

  private showBookmarkedItems = false;

  private initializeInfiniteLoading = 0;

  private hasLoaded = false;

  private setIntervalKey: NodeJS.Timer | null = null;

  private isItTopOfYourFeed = true;

  private latestFeedPostWrapperUid: string | null = null;

  private newestFeedPostWrapperUid: string | null = null;

  private get isANewFeedPostWrapperInTheHouse(): boolean {
    return !!(this.newestFeedPostWrapperUid
        && this.newestFeedPostWrapperUid !== this.latestFeedPostWrapperUid);
  }

  private get isGlobal(): boolean {
    return this.type === FeedType.GLOBAL;
  }

  private get canModifyFeed(): boolean {
    if (this.authUser) {
      switch (this.type) {
        case FeedType.EXHIBITOR:
          return this.authUser ? this.canPostInCompany(this.getUid(this.entityCode)) : false;
        case FeedType.PROFILE:
        case FeedType.COMMUNITY_USER:
          return this.authUser.uid === this.entityUidFeed;
        case FeedType.GLOBAL:
          return this.canPostInCompany(this.entityUidFeed)
              || this.authUser.uid === this.entityUidFeed
              || this.canPostInSubEdition(this.entityUidFeed);
        case FeedType.SUB_EDITION:
          return this.canPostInSubEdition(this.entityUidFeed);
        case FeedType.TOPIC:
        case FeedType.CHANNEL:
          return true;
        default:
          return false;
      }
    }
    return false;
  }

  private get containerId(): string | null {
    return (this.type === FeedType.TOPIC || this.type === FeedType.CHANNEL) ? this.getUid(this.entityCode) : null;
  }

  private get isTopic(): boolean {
    return this.type === FeedType.TOPIC;
  }

  private get communityUserIsAnOrganizerWhenItsNotGlobalFeed(): boolean {
    switch (this.type) {
      case FeedType.SUB_EDITION:
      case FeedType.TOPIC:
      case FeedType.EXHIBITOR:
      case FeedType.CHANNEL:
        return this.isTopicPublicPosting ? true : this.authUser.isAnOrganiser;

      default:
        return true;
    }
  }

  created(): void {
    this.setDataConfig();
    window.addEventListener('scroll', this.onScroll);
    this.$eventsBus.on('on-poll-create-refresh', this.onRefreshFeedItems);
  }

  @Watch('type')
  setFeedType(): void {
    this.$set(this.feedType, 'value', this.type);
    if (!this.hasLoaded && !this.isGlobal && this.type !== FeedType.TOPIC) {
      this.infiniteHandler();
    }
  }

  @Watch('isReadyToDisplay')
  loadConfig(): void {
    if (this.entityCode && this.isReadyToDisplay) {
      this.getFeedStateForAuthUser()
        .finally(() => {
          if ((this.entityCode && this.getUid(this.entityCode) !== this.entityUidFeed)
                || (this.fetchLastFeedType !== this.type)) {
            this.initialize();
          }
          this.setCompanyId();
          this.getTopicsList({
            userUid: this.authUser.uid,
            entityUid: this.getUid(this.entityCode),
            isOrganiser: this.authUser.isAnOrganiser,
          });
          if (this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_ACTIVITY_FEED)
                && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_ACTIVITY_FEED)) {
            this.setInitiator()
              .then(() => {
                if (this.type === FeedType.SUB_EDITION) {
                  this.infiniteHandler(null);
                }
              });
          }
          this.$root.$on('remove-feed-item-bookmark', (uid: string) => {
            if (uid && this.showBookmarkedItems) {
              this.removePost(uid);
              this.isEmpty = this.fetchCategories.every((c) => c.items.length === 0);
            }
          });

          // Set loop calls to get latest feed post
          this.setIntervalKey = setInterval(() => {
            this.loadLatestFeed({
              type: this.type,
              uid: this.getUid(this.entityCode),
              authUserId: this.authUser ? this.authUser.uid : '',
              bookmarks: this.showBookmarkedItems,
            })
              .then((feedWrapper) => {
                if (feedWrapper) {
                  this.newestFeedPostWrapperUid = feedWrapper.uid;
                }
                if (this.isANewFeedPostWrapperInTheHouse) {
                  this.$eventsBus.emit('remove-feed-up-to-date');
                }
              });
          }, LATEST_FEED_POST_DELAY);

          this.$eventsBus.on('feed-post-created', this.onFeedPostCreated);
        });
    }
  }

  getUid(params: string): string {
    let uid = '';
    if (this.type === FeedType.PROFILE) {
      uid = this.$store.state.authUser ? this.$store.state.authUser.uid : '';
    } else {
      uid = params.replaceAll(
        /"*%authUser%"*/g,
        `${this.authUser ? this.authUser.uid : ''}`,
      );
      const propKeys = Object.keys(this.$route.params);
      propKeys.forEach((key) => {
        if (this.$route.params[key]) {
          uid = uid.replaceAll(`%${key}%`, `${this.$route.params[key]}`);
        }
      });
    }
    return uid;
  }

  beforeDestroy(): void {
    window.removeEventListener('scroll', this.onScroll);
    this.$eventsBus.off('feed-post-created', this.onFeedPostCreated);
    if (this.setIntervalKey) {
      clearInterval(this.setIntervalKey);
    }
    this.initialize();
    this.hasLoaded = false;
  }

  updateLatestFeedPostWrapperUid(): void {
    const category = this.fetchCategories.find((cat) => cat.items.length > 0);
    if (category) {
      this.latestFeedPostWrapperUid = category.items[0].uid;
    }
  }

  private onFeedPostCreated(event?: Event, payload?: EventCallback): void {
    this.latestFeedPostWrapperUid = payload as string;
    this.newestFeedPostWrapperUid = payload as string;
  }

  private onScroll(): void {
    if (this.$el) {
      const firstFeedItemEl = this.$el.querySelector('.infinite-wrapper .feed-item');
      this.isItTopOfYourFeed = !!firstFeedItemEl && firstFeedItemEl.getBoundingClientRect().top > 0;
    }
  }

  private onNewFeedPostWrapperButtonClick(scroll = true): void {
    if (scroll) {
      window.scrollTo({ top: 0 });
    }
    this.latestFeedPostWrapperUid = this.newestFeedPostWrapperUid;
    this.onRefreshFeedItems();
  }

  private displayBookmarkedFeedItems(): void {
    this.showBookmarkedItems = !this.showBookmarkedItems;
    this.initialize();
    this.initializeInfiniteLoading += 1;
  }

  private onRefreshFeedItems(): void {
    this.initialize();
    this.initializeInfiniteLoading += 1;
  }

  private setInitiator(): Promise<void> {
    switch (this.type) {
      case FeedType.EXHIBITOR:
        return Promise.resolve(this.loadFeedExhibitor({
          uid: this.getUid(this.entityCode),
          authUser: this.authUser ? this.authUser.uid : '',
        }));
      case FeedType.PROFILE:
      case FeedType.COMMUNITY_USER:
      case FeedType.GLOBAL:
        return Promise.resolve(this.setFeedInitiator(this.authUser));
      case FeedType.SUB_EDITION:
        return Promise.resolve(this.getFeedSubEdition(this.getUid(this.entityCode)));
      default:
        return Promise.resolve(this.setFeedInitiator(this.authUser));
    }
  }

  @Watch('fetchCategories', { deep: true })
  private updateLastViewedTimeStamp(): void {
    if (this.pageNumber === 1) {
      const categoryWrapper = this.fetchCategories
        .find((category, index) => index > 0 && category.items && category.items.length > 0);
      if (this.isGlobal) {
        if (categoryWrapper !== undefined && categoryWrapper.items[0].updatedTimestamp
            && ((this.authUser.lastViewedFeeds
                    && this.authUser.lastViewedFeeds < categoryWrapper.items[0].updatedTimestamp)
                || !this.authUser.lastViewedFeeds)) {
          this.updateLastViewedFeedsGlobal(DateTimeHelper.currentTimestamp);
        }
      } else if (this.entityUidFeed) {
        const userFeedState = (this.authUser.feedStates?.find((e) => e.feedEntity?.uid === this.entityUidFeed));
        if (!userFeedState) {
          this.createFeedState({
            feedUid: this.entityUidFeed,
            lastView: DateTimeHelper.currentTimestamp,
            userUid: this.authUser.uid,
          });
        } else if ((categoryWrapper !== undefined && categoryWrapper.items[0].updatedTimestamp
            && ((userFeedState.lastViewedFeed
                && userFeedState.lastViewedFeed < categoryWrapper.items[0].updatedTimestamp)))) {
          this.updateFeedState({
            lastView: DateTimeHelper.currentTimestamp,
            feedStateUid: userFeedState.uid,
          });
        }
      }
    }
  }

  private infiniteHandler($state?: StateChanger | null): void {
    if (this.type) {
      this.hasLoaded = true;
      this.isLoading = true;
      this.loadFeeds({
        type: this.type,
        uid: this.getUid(this.entityCode),
        authUserId: this.authUser ? this.authUser.uid : '',
        bookmarks: this.showBookmarkedItems,
      })
        .then((feedWrapperCount) => {
          this.isLoading = false;
          if ($state) {
            if (feedWrapperCount === 8) {
              $state.loaded();
            } else {
              this.showLastViewed = this.authUser
                    && !this.authUser.lastViewedFeeds
                    && this.isGlobal;
              $state.complete();
            }
          }
          this.isEmpty = feedWrapperCount === 0 && this.pageNumber === 0;
          this.updateLatestFeedPostWrapperUid();
        })
        .catch(() => {
          if ($state) {
            $state.complete();
          }
        });
    } else {
      this.isEmpty = true;
    }
  }

  @Watch('authUser', { deep: true })
  private setCompanyId(): void {
    if (this.authUser
        && this.authUser.companyRoles
        && this.authUser.companyRoles.length > 0
        && this.authUser.companyRoles[0].company
        && this.authUser.companyRoles[0].company.uid) {
      this.$set(this.companyId, 'value', this.authUser.companyRoles[0].company.uid);
    }
  }
}
