import { render, staticRenderFns } from "./FeedWidget.vue?vue&type=template&id=c4acd7d0&scoped=true&"
import script from "./FeedWidget.vue?vue&type=script&lang=ts&"
export * from "./FeedWidget.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/feed/FeedPage.scss?vue&type=style&index=0&id=c4acd7d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4acd7d0",
  null
  
)

export default component.exports